import React, { Component } from "react";
import { Input, Segment, Select, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import NumberInput from "../../assets/components/NumberInput";
import { isBefore, startOfDay } from "date-fns";
import Partial500 from "../errors/partials/500";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Event from "../../models/Event";
import GolfClub from "../../models/GolfClub";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import DatePicker from "../../assets/components/DatePicker";
import CountrySelect from "../../assets/components/CountrySelect";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            notification: null,
            event: new Event({
                date: new Date(),
                price: 0,
                category: "member",
                noGuests: false,
                spacesLeftTrigger: 0,
                bookingCutOff: new Date(),
                amendCutOff: new Date(),
            }),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.categories = [
            { key: 0, text: "Member", value: "member" },
            { key: 1, text: "Public", value: "public" },
            { key: 2, text: "Roll-up", value: "roll-up" },
        ];

        this.bookingLinkTypes = [
            { key: 0, text: "URL", value: "url" },
            { key: 1, text: "Phone number", value: "phoneNumber" },
            { key: 2, text: "Email address", value: "email" },
        ];
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className='has-error'>
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors),
            );
        }
    }

    gatherInput(property, value) {
        const { event } = this.state;
        event[property] = value;
        this.setState({ event });
    }

    onChangeCoordinates(index, value) {
        const { event } = this.state;
        event.coordinates[index] = value;
        this.setState({ event });
    }

    save() {
        this.setState({ saving: true });
        const { event } = this.state;
        event
            .store()
            .then((response) => {
                this.props.history.push("/events/" + response._id);
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save event");
                this.handleValidationErrors(error);
            });
    }

    async onChangeClub(value) {
        const { event } = this.state;
        const club = await GolfClub.show(value);
        event.club = value;
        event.coordinates = club.hasValidCoordinates() ? club.location : [];
        event.locationAddressLine1 = club.address1;
        event.locationAddressLine2 = club.address2;
        event.locationAddressTown = club.town;
        event.locationAddressCounty = club.county;
        event.locationAddressPostcode = club.postcode;
        event.locationAddressCountry = club.country;
        this.setState({ event });
    }

    onChangeCategory(e, result) {
        const { event } = this.state;
        event.category = result.value;
        this.setState({ event });
    }

    onChangeDate(selectedDay) {
        const { event } = this.state;
        event.date = startOfDay(selectedDay);
        this.setState({ event });
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className='container'>
                <div className='row skinny'>
                    <div className='col'>
                        <div className={"mt-3"}>
                            <h2>Create a new event</h2>
                            <Segment vertical>
                                {this.errorsFor("name")}
                                <label className='with-input'>Name</label>
                                <Input
                                    fluid
                                    name='name'
                                    value={this.state.event.name || ""}
                                    onChange={({ target }) =>
                                        this.gatherInput("name", target.value)
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("price")}
                                <label className='with-input'>Price</label>
                                <NumberInput
                                    fluid
                                    decimal
                                    icon='gbp'
                                    iconPosition='left'
                                    name='price'
                                    value={this.state.event.price}
                                    onChange={(value) =>
                                        this.gatherInput("price", value)
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                <p>Event category</p>
                                <p>
                                    Is this a public , members-only or roll-up
                                    event?
                                </p>
                                <Select
                                    fluid
                                    value={this.state.event.category}
                                    options={this.categories}
                                    onChange={this.onChangeCategory.bind(this)}
                                    placeholder='Select an event category'
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("date")}
                                <label className='with-input'>Date</label>
                                <DatePicker
                                    value={this.state.event.date}
                                    onDayChange={(value) => {
                                        this.onChangeDate(value);
                                    }}
                                    dayPickerProps={{
                                        disabledDays: (day) =>
                                            isBefore(day, new Date()),
                                    }}
                                />
                            </Segment>
                            <Segment vertical>
                                <label className='with-input'>Club</label>
                                <GolfClubSearchSelect
                                    fluid
                                    value={this.state.event.club ?? null}
                                    onChange={(value) =>
                                        this.onChangeClub(value)
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("coordinates")}
                                <div className='row'>
                                    <div className='col'>
                                        <label className='with-input'>
                                            Latitude
                                        </label>
                                        <Input
                                            fluid
                                            value={
                                                this.state.event
                                                    .coordinates[1] ?? ""
                                            }
                                            onChange={({ target }) =>
                                                this.onChangeCoordinates(
                                                    1,
                                                    target.value,
                                                )
                                            }
                                        />
                                    </div>

                                    <div className='col'>
                                        <label className='with-input'>
                                            Longitude
                                        </label>
                                        <Input
                                            fluid
                                            value={
                                                this.state.event
                                                    .coordinates[0] ?? ""
                                            }
                                            onChange={({ target }) =>
                                                this.onChangeCoordinates(
                                                    0,
                                                    target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("locationAddressLine1")}
                                <label className='with-input'>
                                    Address line 1
                                </label>
                                <Input
                                    fluid
                                    name='locationAddressLine1'
                                    value={
                                        this.state.event.locationAddressLine1 ||
                                        ""
                                    }
                                    onChange={({ target }) =>
                                        this.gatherInput(
                                            "locationAddressLine1",
                                            target.value,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("locationAddressLine2")}
                                <label className='with-input'>
                                    Address line 2
                                </label>
                                <Input
                                    fluid
                                    name='locationAddressLine2'
                                    value={
                                        this.state.event.locationAddressLine2 ||
                                        ""
                                    }
                                    onChange={({ target }) =>
                                        this.gatherInput(
                                            "locationAddressLine2",
                                            target.value,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("locationAddressTown")}
                                <label className='with-input'>Town</label>
                                <Input
                                    fluid
                                    name='locationAddressTown'
                                    value={
                                        this.state.event.locationAddressTown ||
                                        ""
                                    }
                                    onChange={({ target }) =>
                                        this.gatherInput(
                                            "locationAddressTown",
                                            target.value,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("locationAddressCounty")}
                                <label className='with-input'>County</label>
                                <Input
                                    fluid
                                    name='locationAddressCounty'
                                    value={
                                        this.state.event
                                            .locationAddressCounty || ""
                                    }
                                    onChange={({ target }) =>
                                        this.gatherInput(
                                            "locationAddressCounty",
                                            target.value,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("locationAddressPostcode")}
                                <label className='with-input'>Postcode</label>
                                <Input
                                    fluid
                                    name='locationAddressPostcode'
                                    value={
                                        this.state.event
                                            .locationAddressPostcode || ""
                                    }
                                    onChange={({ target }) =>
                                        this.gatherInput(
                                            "locationAddressPostcode",
                                            target.value,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                {this.errorsFor("locationAddressCountry")}
                                <label className='with-input'>Country</label>
                                <CountrySelect
                                    fluid
                                    value={
                                        this.state.event
                                            .locationAddressCountry || ""
                                    }
                                    onChange={(value) => {
                                        this.gatherInput(
                                            "locationAddressCountry",
                                            value,
                                        );
                                    }}
                                />
                            </Segment>
                            <Segment vertical>
                                <Button
                                    size='large'
                                    floated='right'
                                    className='theme'
                                    primary
                                    onClick={this.save.bind(this)}
                                    disabled={this.state.saving}
                                    loading={this.state.saving}
                                >
                                    Save
                                </Button>
                            </Segment>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className='d-flex main'>
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
