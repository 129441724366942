import React, { Component } from "react";
import { Input, Segment, Form, Select, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Communication from "../../models/Communication";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import HtmlEditor from "../../assets/components/HtmlEditor";
import config from "../../config";
import EntitySearchSelect from "../../assets/components/EntitySearchSelect";
import ImageSelect from "../../assets/components/communication/ImageSelect";
import MembershipTypeSearchSelect from "../../assets/components/MembershipTypeSearchSelect";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            notification: null,
            communication: new Communication(),
            showImageSelectionOptions: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.recipientTypes = [
            { key: 0, value: "sendToAll", text: "Send to all" },
            { key: 1, value: "sendToEntities", text: "Send to entities" },
            { key: 2, value: "sendToRoles", text: "Send to membership types" },
            { key: 3, value: "sendToUsers", text: "Send to users" },
        ];

        this.communicationTypes = [
            { key: 0, value: "SERVICE", text: "Service" },
            {
                key: 1,
                value: "FIRST_PARTY_MARKETING",
                text: "First party marketing",
            },
            {
                key: 2,
                value: "THIRD_PARTY_MARKETING",
                text: "Third party marketing",
            },
        ];
    }

    onChangeSubject(text) {
        const { communication } = this.state;
        communication.subject = text;
        this.setState({ communication });
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className='has-error'>
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors),
            );
        }
    }

    gatherInput(event) {
        const { communication } = this.state;
        communication[event.target.name] = event.target.value;
        this.setState({ communication });
    }

    save() {
        this.setState({ saving: true });
        this.state.communication
            .store()
            .then((response) => {
                this.props.history.push("/communications/" + response._id);
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save communication");
                this.handleValidationErrors(error);
            });
    }

    onChangeEntities(value) {
        const { communication } = this.state;
        communication.sendToEntities = value;
        this.setState({ communication });
    }

    onChangeToggleSendToAll() {
        const { communication } = this.state;
        communication.sendToAll = !communication.sendToAll;
        this.setState({ communication });
    }

    onChangeRoles(value) {
        const { communication } = this.state;
        communication.sendToRoles = value;
        this.setState({ communication });
    }

    onChangeMembers(value) {
        const { communication } = this.state;
        communication.sendToUsers = value;
        this.setState({ communication, showRecipients: false });
    }

    onChangeCommunicationType(event, props) {
        const { communication } = this.state;
        const { value } = props;
        communication.communicationType = value;
        this.setState({ communication });
    }

    onChangeRecipientType(event, props) {
        const { communication } = this.state;
        const { value } = props;
        communication.recipientType = value;
        this.setState({ communication });
    }

    onChangeBody(text) {
        const { communication } = this.state;
        communication.body = text;
        this.setState({ communication });
    }

    onChangeImage(value) {
        if (value === null) {
            return window.alert(
                "That resource does not have a suitable image. Please try something else.",
            );
        }
        const { communication } = this.state;
        communication.golfClubImage = config.content_url + value;
        this.setState({ communication, showImageSelectionOptions: false });
    }

    imageSelectionOptions() {
        if (this.state.showImageSelectionOptions === false) {
            return (
                <Button
                    className='mt-3'
                    onClick={() =>
                        this.setState({ showImageSelectionOptions: true })
                    }
                >
                    Choose image
                </Button>
            );
        }

        return <ImageSelect onChange={this.onChangeImage.bind(this)} />;
    }

    showAdditionalRecipientCriteria() {
        if (this.state.communication.recipientType === "sendToEntities") {
            return (
                <Segment vertical>
                    <label className='with-input'>Send to Entities?</label>
                    <p>
                        <small>Select a list of entities to send to.</small>
                    </p>
                    <EntitySearchSelect
                        multiple
                        fluid
                        value={this.state.communication.sendToEntities}
                        onChange={(value) => this.onChangeEntities(value)}
                    />
                </Segment>
            );
        }

        if (this.state.communication.recipientType === "sendToRoles") {
            return (
                <Segment vertical>
                    <label className='with-input'>
                        Send to Membership Types?
                    </label>
                    <MembershipTypeSearchSelect
                        fluid
                        value={this.state.communication.sendToRoles}
                        placeholder='Select which membership types to send to'
                        onChange={(value) => this.onChangeRoles(value)}
                    />
                </Segment>
            );
        }

        if (this.state.communication.recipientType === "sendToUsers") {
            return (
                <Segment vertical>
                    <label className='with-input'>Send to Members?</label>
                    <MemberSearchSelect
                        onChange={this.onChangeMembers.bind(this)}
                        value={this.state.communication.sendToUsers}
                        multiple
                        placeholder='Select which members to send to'
                        active
                        fluid
                    />
                </Segment>
            );
        }

        if (this.state.communication.recipientType === "sendToAll") {
            return (
                <p className='mt-3'>
                    <small>
                        NOTE: this communication will be sent to{" "}
                        <b>
                            <i>ALL</i>
                        </b>{" "}
                        members in the system
                    </small>
                </p>
            );
        }
    }

    communicationDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Set the send information</p>
                <Segment vertical>
                    {this.errorsFor("subject")}
                    <label className='with-input'>Subject</label>
                    <Input
                        fluid
                        name='subject'
                        value={this.state.communication.subject || ""}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                {this.imageSelectionOptions()}
                {this.state.communication.golfClubImage && (
                    <Segment vertical>
                        <div className='row'>
                            <div className='col-12 mt-3 text-center'>
                                <img
                                    className='w-25'
                                    src={this.state.communication.golfClubImage}
                                />
                            </div>
                        </div>
                    </Segment>
                )}
                <Segment vertical>
                    {this.errorsFor("body")}
                    <label className='with-input'>Body</label>
                    <Form>
                        <HtmlEditor
                            placeholder='Body'
                            content={this.state.communication.body || ""}
                            onChange={this.onChangeBody.bind(this)}
                        />
                    </Form>
                </Segment>
                <Segment vertical>
                    <label className='with-input'>
                        Choose communication type
                    </label>
                    <Select
                        fluid
                        value={this.state.communication.communicationType}
                        options={this.communicationTypes}
                        onChange={this.onChangeCommunicationType.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Choose recipients</label>
                    <Select
                        fluid
                        value={this.state.communication.recipientType}
                        options={this.recipientTypes}
                        onChange={this.onChangeRecipientType.bind(this)}
                    />
                </Segment>
                {this.showAdditionalRecipientCriteria()}
                <Segment vertical>
                    <Button
                        floated='right'
                        className='theme'
                        primary
                        onClick={this.save.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className='container'>
                <div className='row skinny'>
                    <div className='col'>
                        <Tabs
                            defaultActiveKey='details'
                            id='uncontrolled-tab-example'
                        >
                            <Tab eventKey='details' title='Details'>
                                {this.communicationDetailsTab()}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className='d-flex main'>
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
