import React, { Component } from "react";
import { Input, Segment, Form, Select, Button, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Communication from "../../models/Communication";
import HtmlEditor from "../../assets/components/HtmlEditor";
import CenterLoader from "../../assets/components/CenterLoader";
import DataTable from "../../assets/components/dataTable";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import EntitySearchSelect from "../../assets/components/EntitySearchSelect";
import ImageSelect from "../../assets/components/communication/ImageSelect";
import config from "../../config";
import MembershipTypeSearchSelect from "../../assets/components/MembershipTypeSearchSelect";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: true,
            error: null,
            notification: null,
            communication: new Communication(),
            recipients: [],
            recipientsTotalRecords: 0,
            recipientsPage: 1,
            showRecipients: true,
            showImageSelectionOptions: false,
            testSend: {
                recipient: "",
                show: false,
            },
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.categories = [
            { key: 0, text: "Golf", value: "golf" },
            { key: 1, text: "Networking", value: "networking" },
            { key: 2, text: "Hotel", value: "hotel" },
            { key: 3, text: "Sports", value: "sports" },
        ];

        this.recipientTypes = [
            { key: 0, value: "sendToAll", text: "Send to all" },
            { key: 1, value: "sendToEntities", text: "Send to entities" },
            { key: 2, value: "sendToRoles", text: "Send to membership types" },
            { key: 3, value: "sendToUsers", text: "Send to users" },
        ];

        this.communicationTypes = [
            { key: 0, value: "SERVICE", text: "Service" },
            {
                key: 1,
                value: "FIRST_PARTY_MARKETING",
                text: "First party marketing",
            },
            {
                key: 2,
                value: "THIRD_PARTY_MARKETING",
                text: "Third party marketing",
            },
        ];
    }

    componentDidMount() {
        this.getCommunication();
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className='has-error'>
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    getRecipients(communication, params) {
        communication.getRecipients(params).then((data) =>
            this.setState({
                recipients: data.recipients,
                recipientsPage: params.page,
                recipientsTotalRecords: data.total,
            }),
        );
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors),
            );
        }
    }

    toggleTestSend() {
        const { testSend } = this.state;
        testSend.show = testSend.show === false;
        this.setState({ testSend });
    }

    gatherTestSendRecipient(value) {
        const { testSend } = this.state;
        testSend.recipient = value;
        this.setState({ testSend });
    }

    gatherInput(event) {
        const { communication } = this.state;
        communication[event.target.name] = event.target.value;
        this.setState({ communication });
    }

    checkPollStatus(communication) {
        if (communication.inPollingStatus() === true) {
            setTimeout(
                async function () {
                    const { status } = await communication.getStatus();
                    communication.status = status;
                    this.setState({ communication });
                    this.checkPollStatus(communication);
                }.bind(this),
                2000,
            );
        } else {
            this.getRecipients(communication, {
                page: this.state.recipientsPage,
            });
        }
    }

    getCommunication() {
        const { id } = this.props.match.params;
        Communication.getById(id)
            .then((communication) => {
                this.checkPollStatus(communication);
                this.setState({ loading: false, communication });
            })
            .catch((error) => {
                this.setState({ loading: false, error: error.response.status });
                this.notification(
                    "error",
                    "Failed to load communication, please try again",
                );
            });
    }

    save() {
        this.setState({ saving: true });
        this.state.communication
            .update()
            .then(() => {
                this.setState({
                    saving: false,
                    showRecipients: true,
                });
                this.notification("success", "Communication saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save communication");
                this.handleValidationErrors(error);
            });
    }

    sendTest() {
        this.state.communication
            .sendTest(this.state.testSend.recipient)
            .then(() => {
                this.setState({ testSend: { show: false, recipient: "" } });
                this.notification("success", "Test communication sent");
            })
            .catch((error) => {
                this.notification("error", "Failed to send test communication");
                this.handleValidationErrors(error);
            });
    }

    send() {
        const confirm = window.confirm(
            "Are you sure you want to send communication?",
        );
        const { communication } = this.state;
        if (confirm === true) {
            this.setState({ saving: true });
            communication
                .send()
                .then(() => {
                    communication.sentAt = new Date();
                    communication.status = "AWAITING_SEND";
                    this.setState({
                        saving: false,
                        communication,
                    });

                    this.getRecipients(communication, {
                        page: this.state.recipientsPage,
                    });
                    this.notification(
                        "success",
                        "Communication queued for sending",
                    );
                    this.checkPollStatus(communication);
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    this.notification("error", "Failed to send communication");
                });
        }
    }

    onChangeEntities(value) {
        const { communication } = this.state;
        communication.sendToEntities = value;
        this.setState({ communication });
    }

    onChangeRoles(value) {
        const { communication } = this.state;
        communication.sendToRoles = value;
        this.setState({ communication, showRecipients: false });
    }

    onChangeMembers(value) {
        const { communication } = this.state;
        communication.sendToUsers = value;
        this.setState({ communication, showRecipients: false });
    }

    onChangeCommunicationType(event, props) {
        const { communication } = this.state;
        const { value } = props;
        communication.communicationType = value;
        this.setState({ communication });
    }

    onChangeRecipientType(event, props) {
        const { communication } = this.state;
        const { value } = props;
        communication.recipientType = value;
        this.setState({ communication, showRecipients: false });
    }

    onChangeImage(value) {
        if (value === null) {
            return window.alert(
                "That resource does not have a suitable image. Please try something else.",
            );
        }
        const { communication } = this.state;
        communication.golfClubImage = config.content_url + value;
        this.setState({ communication, showImageSelectionOptions: false });
    }

    onChangeBody(text) {
        const { communication } = this.state;
        communication.body = text;
        this.setState({ communication });
    }

    showAdditionalRecipientCriteria() {
        if (this.state.communication.recipientType === "sendToEntities") {
            return (
                <Segment vertical>
                    <label className='with-input'>Send to Entities</label>
                    <p>
                        <small>Select a list of entities to send to.</small>
                    </p>
                    <EntitySearchSelect
                        multiple
                        fluid
                        value={this.state.communication.sendToEntities}
                        onChange={(value) => this.onChangeEntities(value)}
                        disabled={this.state.communication.canEdit() === false}
                    />
                </Segment>
            );
        }

        if (this.state.communication.recipientType === "sendToRoles") {
            return (
                <Segment vertical>
                    <label className='with-input'>
                        Send to Membership Types
                    </label>
                    <MembershipTypeSearchSelect
                        fluid
                        value={this.state.communication.sendToRoles}
                        disabled={this.state.communication.canEdit() === false}
                        placeholder='Select which membership types to send to'
                        onChange={(value) => this.onChangeRoles(value)}
                    />
                </Segment>
            );
        }

        if (this.state.communication.recipientType === "sendToUsers") {
            return (
                <Segment vertical>
                    <label className='with-input'>Send to Members</label>
                    <MemberSearchSelect
                        onChange={this.onChangeMembers.bind(this)}
                        value={this.state.communication.sendToUsers}
                        multiple
                        disabled={this.state.communication.canEdit() === false}
                        placeholder='Select which members to send to'
                        active
                        fluid
                    />
                </Segment>
            );
        }

        if (this.state.communication.recipientType === "sendToAll") {
            return (
                <p className='mt-3'>
                    <small>
                        NOTE: this communication will be sent to{" "}
                        <b>
                            <i>ALL</i>
                        </b>{" "}
                        members in the system
                    </small>
                </p>
            );
        }
    }

    imageSelectionOptions() {
        if (this.state.showImageSelectionOptions === false) {
            return (
                <Button
                    className='mt-3'
                    onClick={() =>
                        this.setState({ showImageSelectionOptions: true })
                    }
                    disabled={this.state.communication.canEdit() === false}
                >
                    Choose image
                </Button>
            );
        }

        return <ImageSelect onChange={this.onChangeImage.bind(this)} />;
    }

    communicationDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Set the send information</p>
                <Segment vertical>
                    {this.errorsFor("subject")}
                    <label className='with-input'>Subject</label>
                    <Input
                        fluid
                        name='subject'
                        value={this.state.communication.subject || ""}
                        disabled={this.state.communication.canEdit() === false}
                        onChange={this.gatherInput.bind(this)}
                    />
                </Segment>
                {this.imageSelectionOptions()}
                {this.state.communication.golfClubImage && (
                    <Segment vertical>
                        <div className='row'>
                            <div className='col-12 mt-3 text-center'>
                                <img
                                    className='w-25'
                                    alt='Golf club'
                                    src={this.state.communication.golfClubImage}
                                />
                            </div>
                        </div>
                    </Segment>
                )}
                <Segment vertical>
                    {this.errorsFor("body")}
                    <label className='with-input'>Body</label>
                    <Form>
                        <HtmlEditor
                            disabled={
                                this.state.communication.canEdit() === false
                            }
                            placeholder='Body'
                            content={this.state.communication.body || ""}
                            onChange={this.onChangeBody.bind(this)}
                        />
                    </Form>
                </Segment>
                <Segment vertical>
                    <label className='with-input'>
                        Choose communication type
                    </label>
                    <Select
                        disabled={this.state.communication.canEdit() === false}
                        fluid
                        value={this.state.communication.communicationType}
                        options={this.communicationTypes}
                        onChange={this.onChangeCommunicationType.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Choose recipients</label>
                    <Select
                        fluid
                        value={this.state.communication.recipientType}
                        options={this.recipientTypes}
                        disabled={this.state.communication.canEdit() === false}
                        onChange={this.onChangeRecipientType.bind(this)}
                    />
                </Segment>
                {this.showAdditionalRecipientCriteria()}
                <Segment vertical>
                    {this.state.testSend.show ? (
                        <div className='row'>
                            <div className='col'>
                                <label className='with-input'>Recipient</label>
                                {this.errorsFor("recipient")}
                                <p>
                                    <small>
                                        Send a test version of this
                                        communication to the given email
                                        address.
                                    </small>
                                </p>
                                <Input
                                    name='Test send recipient'
                                    value={this.state.testSend.recipient}
                                    onChange={({ target }) =>
                                        this.gatherTestSendRecipient(
                                            target.value,
                                        )
                                    }
                                />
                                <Button
                                    className='theme ml-1'
                                    primary
                                    disabled={
                                        this.state.testSend.recipient.length < 6
                                    }
                                    onClick={this.sendTest.bind(this)}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='row'>
                            <div className='col'>
                                <Button
                                    className='theme'
                                    primary
                                    onClick={this.toggleTestSend.bind(this)}
                                    disabled={this.state.saving}
                                    loading={this.state.saving}
                                >
                                    Send test
                                </Button>
                                {this.state.communication.canSend() && (
                                    <Button
                                        className='theme'
                                        primary
                                        onClick={this.save.bind(this)}
                                        disabled={this.state.saving}
                                        loading={this.state.saving}
                                    >
                                        Save
                                    </Button>
                                )}
                            </div>
                            <div className='col'>
                                {this.state.communication.canSend() && (
                                    <Button
                                        floated='right'
                                        className='theme'
                                        primary
                                        onClick={this.send.bind(this)}
                                        disabled={this.state.saving}
                                        loading={this.state.saving}
                                    >
                                        {this.state.communication.status ===
                                        "DRAFT"
                                            ? "Send"
                                            : "Resend to failures"}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </Segment>
            </div>
        );
    }

    onTabChange(tab) {
        if (tab === "recipients") {
            this.getRecipients(this.state.communication, {
                page: this.state.recipientsPage,
            });
        }
    }

    recipientStatus(status) {
        if (status === "FAILED") {
            return <Label color='red'>Failed</Label>;
        }

        if (status === "SENT") {
            return <Label color='green'>Sent</Label>;
        }

        if (status === "NOT_YET_SENT") {
            return <Label color='blue'>Not yet sent</Label>;
        }

        return <Label color='grey'>Unknown</Label>;
    }

    communicationRecipientsTab() {
        if (this.state.showRecipients === false) {
            return (
                <h4 className='mt-3'>Please save changes to view recipients</h4>
            );
        }

        const headers = [
            { key: "name", text: "Name" },
            { key: "status", text: "Status" },
            { key: "entity", text: "Entity" },
            { key: "membership", text: "Membership" },
        ];

        const data = this.state.recipients.map((recipient) => {
            const membership = recipient.getMembershipStatus();

            return {
                name: { value: recipient.name, search: recipient.name },
                status: { value: this.recipientStatus(recipient.status) },
                entity: {
                    value: recipient.entity.entity.name,
                    search: recipient.entity.entity.name,
                },
                membership: {
                    value: membership.label,
                    search: membership.expiry,
                },
            };
        });

        return (
            <div className={styles.mainSegment}>
                <h3>Recipients for this communication</h3>
                <p>
                    Based on the criteria listed in the details page, this
                    communication will be sent to the following users.
                </p>
                <DataTable
                    headers={headers}
                    data={data}
                    page={this.state.recipientsPage}
                    totalRecords={this.state.recipientsTotalRecords}
                    onChange={(params) =>
                        this.getRecipients(this.state.communication, params)
                    }
                    pagination
                />
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.state.loading === true) {
            return <CenterLoader />;
        }

        return (
            <div className='container'>
                <div className='row skinny'>
                    <div className='col'>
                        {this.state.communication.statusLabel()}
                        <p className='mt-3 mb-3'>
                            {this.state.communication.statusDescription()}
                        </p>
                    </div>
                </div>
                <div className='row skinny mt-3'>
                    <div className='col'>
                        <Tabs
                            defaultActiveKey='details'
                            id='uncontrolled-tab-example'
                            onSelect={this.onTabChange.bind(this)}
                        >
                            <Tab eventKey='details' title='Details'>
                                {this.communicationDetailsTab()}
                            </Tab>
                            <Tab eventKey='recipients' title='Recipients'>
                                {this.communicationRecipientsTab()}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className='d-flex main'>
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
