import { Select } from "semantic-ui-react";
import membershipKeys from "../../utilities/membershipRoleKeys";
import formatMembershipKey from "../../utilities/formatMembershipKey";

const MembershipTypeSearchSelect = ({
    onChange,
    value,
    multiple = true,
    fluid = false,
    disabled = false,
    className = "",
    placeholder = "Membership type",
}) => {
    return (
        <Select
            multiple={multiple}
            fluid={fluid}
            disabled={disabled}
            search
            placeholder={placeholder}
            options={membershipKeys.map((key, index) => {
                return {
                    key: index,
                    value: key,
                    text: formatMembershipKey(key),
                };
            })}
            value={value}
            onChange={(_, { value }) => onChange(value)}
            className={className}
        />
    );
};

export default MembershipTypeSearchSelect;
