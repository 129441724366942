import React, { PureComponent } from "react";
import reactElementToJSXString from "react-element-to-jsx-string";
import { Table, Input, Button, Icon, Dropdown } from "semantic-ui-react";
import { sortBy } from "lodash";
import LoadingPlaceholder from "./LoadingPlaceholder";

class DataTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            headers: this.props.headers,
            data: this.props.data,
            limit: this.props.limit ?? 25,
            sort: {
                column: this.props.sort ?? "createdAt",
                order: this.props.order ?? "asc",
            },
            search: this.props.searchTerm || "",
            quickSort: {
                column: null,
                direction: null,
            },
            dataCount: 0,
        };

        this.searchTimer = null;

        this.limitDropdownOptions = [
            { key: 0, text: "5 per page", value: 5 },
            { key: 1, text: "10 per page", value: 10 },
            { key: 2, text: "25 per page", value: 25 },
            { key: 3, text: "50 per page", value: 50 },
            { key: 4, text: "100 per page", value: 100 },
            { key: 5, text: "250 per page", value: 250 },
            { key: 6, text: "View all records", value: 100000 },
        ];
    }

    displayHeaders() {
        const { quickSort } = this.state;
        const { headers } = this.props;
        return headers.map((header, index) => {
            return (
                <Table.HeaderCell
                    sorted={
                        header.key === quickSort.column
                            ? quickSort.direction
                            : null
                    }
                    onClick={() =>
                        this.props.quickSort === false
                            ? null
                            : this.onChangeQuickSort(header.key)
                    }
                    width={header.width}
                    key={index}
                >
                    {header.text}
                </Table.HeaderCell>
            );
        });
    }

    getChangeState() {
        return {
            page: this.props.page,
            limit: this.state.limit,
            sort: this.state.sort.column,
            order: this.state.sort.order,
            term: this.state.search,
        };
    }

    displayBody() {
        const { quickSort } = this.state;
        const { headers } = this.props;
        let data = this.getSearchData();
        if (quickSort.column !== null) {
            data = this.sortData(data);
        }

        return data.map((field, index) => {
            return (
                <Table.Row key={index}>
                    {headers.map((header, i) => (
                        <Table.Cell key={i}>
                            {field[header.key].value}
                        </Table.Cell>
                    ))}
                </Table.Row>
            );
        });
    }

    sortData(data) {
        const { quickSort } = this.state;
        let sorted = sortBy(
            data,
            (properties) => properties[quickSort.column].search,
        );
        if (quickSort.direction === "descending") {
            return sorted.reverse();
        }

        return sorted;
    }

    onChangeSearch(value) {
        this.setState({ search: value });
        // If a callback for state changes was provided, call it
        if (typeof this.props.onChange === "function") {
            const changeState = this.getChangeState();
            changeState.term = value;
            changeState.page = 1;
            if (value.length === 0) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
                return this.props.onChange(changeState);
            }

            if (this.searchTimer !== null) {
                clearTimeout(this.searchTimer);
            }

            this.searchTimer = setTimeout(() => {
                this.props.onChange(changeState);
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }, 1000);
            return;
        }
    }

    onChangePerPageLimit(event, selection) {
        const limit = selection.value;
        const changeState = this.getChangeState();
        changeState.limit = limit;
        this.setState({ limit });
        if (typeof this.props.onChange === "function") {
            this.props.onChange(changeState);
        }
    }

    onChangeSortColumn(event, selection) {
        const { sort } = this.state;
        const column = selection.value;
        const changeState = this.getChangeState();
        changeState.sort = column;
        this.setState({ sort: { order: sort.order, column } });
        if (typeof this.props.onChange === "function") {
            this.props.onChange(changeState);
        }
    }

    onChangeSortOrder(event, selection) {
        const { sort } = this.state;
        sort.order = sort.order === "asc" ? "desc" : "asc";
        const changeState = this.getChangeState();
        changeState.order = sort.order;
        this.setState({ sort });
        if (typeof this.props.onChange === "function") {
            this.props.onChange(changeState);
        }
    }

    onChangeQuickSort(selectedColumn) {
        const { quickSort } = this.state;

        if (quickSort.column === selectedColumn) {
            return this.setState({
                quickSort: {
                    direction:
                        quickSort.direction === "ascending"
                            ? "descending"
                            : "ascending",
                    column: quickSort.column,
                },
            });
        }

        this.setState({
            quickSort: {
                direction: "ascending",
                column: selectedColumn,
            },
        });
    }

    getSearchData() {
        // If a callback was provided for searching, we are using server-side searching
        if (typeof this.props.onChange === "function") {
            return this.props.data;
        }

        // Otherwise, perform the search locally
        if (this.state.search.length === 0) {
            return this.props.data;
        }

        return this.props.data.filter((row) => {
            for (let key in row) {
                const prop = row[key];
                if (typeof prop.search !== "undefined") {
                    if (
                        String(prop.search)
                            .toLowerCase()
                            .includes(String(this.state.search).toLowerCase())
                    ) {
                        return true;
                    }
                }
            }

            return false;
        });
    }

    resultsCount() {
        if (this.props.hideResultsCount === true) {
            return;
        }

        const { dataCount } = this.props;
        let countText = "No records found";
        const totalRecords = this.props.totalRecords || this.props.data.length;
        if (this.props.data.length > 0) {
            countText =
                "Showing " +
                this.getSearchData().length +
                " of " +
                totalRecords +
                " records";
        }

        return (
            <>
                <p>{countText}</p>
                <p>{dataCount}</p>
            </>
        );
    }

    disableNext() {
        return this.props.page >= this.props.totalRecords / this.state.limit;
    }

    onClickNext() {
        const changeState = this.getChangeState();
        changeState.page = this.props.page + 1;
        return this.props.onChange(changeState);
    }

    disablePrevious() {
        return this.props.page === 1;
    }

    onClickPrevious() {
        const changeState = this.getChangeState();
        changeState.page = this.props.page - 1;
        return this.props.onChange(changeState);
        // this.props.previous(this.props.page - 1);
    }

    pagination() {
        if (this.props.pagination === true) {
            return (
                <div className='text-right'>
                    <Button
                        size='tiny'
                        disabled={this.disablePrevious()}
                        onClick={this.onClickPrevious.bind(this)}
                    >
                        Previous
                    </Button>
                    <span className='text-center ml-3 mr-3'>
                        Page {this.props.page}
                    </span>
                    <Button
                        size='tiny'
                        disabled={this.disableNext()}
                        onClick={this.onClickNext.bind(this)}
                    >
                        Next
                    </Button>
                </div>
            );
        }

        return null;
    }

    exportCSV() {
        // Start with BOM for proper UTF-8 recognition by Excel
        let content = "\ufeff";

        // Build the header row without the data URL prefix
        for (const header of this.props.headers) {
            content += `"${header.text}",`;
        }
        content += "\n";

        // Process each data row
        for (const row of this.props.data) {
            const rowData = Object.keys(row)
                .map((key) => {
                    const { value } = row[key];
                    if (typeof value === "object" && value !== null) {
                        // Convert React element to JSX string and remove HTML tags and newlines
                        return `"${reactElementToJSXString(value)
                            .replace(/<[^>]*>/g, "")
                            .replace(/\n/g, "")}"`;
                    }
                    // If no value is available, default to '-'
                    return `"${value ?? "-"}"`;
                })
                .join(",");
            content += rowData + "\n";
        }

        // Generate a filename
        let fileName = (
            new Date().toDateString() +
            "-" +
            document.location.pathname.substring(1)
        )
            .replace(/ /g, "-")
            .replace(/\//g, "-");

        // Use Blob to handle file creation and download
        const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName + ".csv");
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
    }

    showPerPage() {
        if (typeof this.props.limit === "number") {
            return (
                <Dropdown
                    selection
                    options={this.limitDropdownOptions}
                    value={this.state.limit}
                    onChange={this.onChangePerPageLimit.bind(this)}
                    className='ml-3'
                />
            );
        }
    }

    showSorting() {
        if (typeof this.props.sortOptions !== "undefined") {
            const sortingDropdownOptions = this.props.sortOptions.map(
                (option, index) => {
                    return { key: index, text: option.text, value: option.key };
                },
            );

            const { sort } = this.state;
            const sortOrderIcon =
                sort.order === "asc" ? "sort amount down" : "sort amount up";
            return (
                <>
                    <Dropdown
                        selection
                        placeholder='Sort by'
                        className='ml-3'
                        value={sort.column}
                        options={sortingDropdownOptions}
                        onChange={this.onChangeSortColumn.bind(this)}
                    />
                    <Button
                        className='ml-1'
                        icon
                        onClick={this.onChangeSortOrder.bind(this)}
                    >
                        <Icon name={sortOrderIcon} />
                    </Button>
                </>
            );
        }
    }

    render() {
        return (
            <div className='table-container'>
                <div className='table-header'>
                    <div className='row'>
                        <div className='col-8 d-flex align-items-center'>
                            <Button
                                className='m-0'
                                onClick={this.exportCSV.bind(this)}
                            >
                                Export
                            </Button>
                            {this.showPerPage()}
                            {this.showSorting()}
                        </div>
                        <div className='col-4 text-right'>
                            {this.props.hideSearch !== true && (
                                <Input
                                    placeholder='Search'
                                    icon='search'
                                    loading={this.searchTimer !== null}
                                    value={this.state.search}
                                    onChange={({ target }) =>
                                        this.onChangeSearch(target.value)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.props.loading === true ? (
                    <LoadingPlaceholder fluid />
                ) : (
                    <>
                        <Table sortable striped celled>
                            <Table.Header>
                                <Table.Row>{this.displayHeaders()}</Table.Row>
                            </Table.Header>
                            <Table.Body>{this.displayBody()}</Table.Body>
                        </Table>
                        <div className='table-footer'>
                            <div className='row'>
                                <div className='col'>{this.resultsCount()}</div>
                                <div className='col left-right'>
                                    {this.pagination()}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default DataTable;
