import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Input,
    Segment,
    Button,
    Select,
    Modal,
    TextArea,
    Form,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { isBefore, format } from "date-fns";
import { Tabs, Tab } from "react-bootstrap";
import GolfClub from "../../models/GolfClub";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import ValidationError from "../../assets/components/ValidationError";
import DatePicker from "../../assets/components/DatePicker";
import NumberInput from "../../assets/components/NumberInput";
import useDispatchUtilities from "../../utilities/useDispatchUtilities";
import PartnerClubContract from "../../models/PartnerClubContract";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import LoadingPlaceholder from "../../assets/components/LoadingPlaceholder";
import Invoices from "../../assets/components/partnerClubContract/Invoices";
import Usage from "../../assets/components/partnerClubContract/Usage";

const Show = ({ history, user, dispatch, match }) => {
    if (user.has("MASHIE_ADMIN") === false) {
        history.push("/members");
    }

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [courses, setCourses] = useState([]);
    const [contract, setContract] = useState(null);
    const [confirmedClubId, setConfirmedClubId] = useState(null);
    const [courseContracts, setCourseContracts] = useState([]);
    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
    const [usedAdjustment, setUsedAdjustment] = useState(0);
    const [usedAdjustmentReason, setUsedAdjustmentReason] = useState("");
    const { setNotification, setValidationErrors, removeValidationErrors } =
        useDispatchUtilities(dispatch);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const contract = await PartnerClubContract.show(match.params.id);
            contract.setStateManager(setContract);
            setContract(contract);
            const courses = await new GolfClub({
                _id: contract.course.club._id,
            }).coursesIndex({
                sort: "name",
            });
            setCourses(courses);
            setConfirmedClubId(contract.course.club._id);
            fetchSelectedCourseContracts(contract.course._id);
            setLoading(false);
        } catch (e) {
            setNotification("error", "Could not find contract");
        }
    };

    const save = async () => {
        setSaving(true);
        try {
            await contract.update();
            setNotification("success", "Changes saved");
            removeValidationErrors();
        } catch (exception) {
            setValidationErrors(exception);
            setNotification("error", "Failed to save changes");
        }
        setSaving(false);
    };

    const deleteContract = async () => {
        const confirm = window.confirm(
            "Are you sure you want to delete this contract?",
        );
        if (confirm === false) return;
        setSaving(true);
        try {
            await contract.delete();
            setNotification("success", "Contract deleted");
            history.push("/partner-club-contracts");
        } catch (exception) {
            setNotification("error", "Failed to delete contract");
        }
    };

    const fetchSelectedCourseContracts = async (courseId) => {
        const { data } = await PartnerClubContract.index({ course: courseId });
        setCourseContracts(data);
    };

    const onChangeConfirmedClub = async (clubId) => {
        const courses = await new GolfClub({ _id: clubId }).coursesIndex({
            sort: "name",
        });
        setConfirmedClubId(clubId);
        setCourses(courses);
        const selectedCourse = courses[0] ?? null;
        if (selectedCourse !== null) {
            fetchSelectedCourseContracts(selectedCourse._id);
        }
        contract.useStateManager((model) => {
            model.course = selectedCourse ?? null;
        });
    };

    const calculateAvailable = () => {
        return (
            contract.allocated -
            contract.reserved -
            contract.used -
            usedAdjustment
        );
    };

    const storeAdjustment = async () => {
        try {
            await contract.storeAdjustment({
                adjustment: usedAdjustment,
                reason: usedAdjustmentReason,
            });
            setUsedAdjustmentReason("");
            setUsedAdjustment(0);
            setShowAdjustmentModal(false);
            setNotification("success", "Adjustment saved");
            fetchData();
        } catch (exception) {
            setNotification("error", "Failed to save adjustment");
        }
    };

    return (
        <>
            <div className='d-flex main'>
                <Header history={history} />
                <div className='container skinny'>
                    <div className='row skinny'>
                        <div className='col'>
                            {loading ? (
                                <LoadingPlaceholder fluid />
                            ) : (
                                <>
                                    <Tabs defaultActiveKey='details'>
                                        <Tab eventKey='details' title='Details'>
                                            {contract.used > 0 && (
                                                <p className='mt-5'>
                                                    <strong>
                                                        This contract has been
                                                        used. It can therefore
                                                        no longer be edited
                                                    </strong>
                                                </p>
                                            )}
                                            <Segment vertical>
                                                <label className='with-input'>
                                                    Contract type <b>*</b>
                                                </label>
                                                <Select
                                                    disabled={contract.used > 0}
                                                    value={
                                                        contract.contractType
                                                    }
                                                    onChange={(
                                                        _,
                                                        { value },
                                                    ) => {
                                                        contract.useStateManager(
                                                            (model) => {
                                                                model.contractType =
                                                                    value;
                                                                if (
                                                                    value ===
                                                                    "CORPORATE"
                                                                ) {
                                                                    model.allocated =
                                                                        null;
                                                                    model.reserved = 0;
                                                                } else {
                                                                    model.allocated = 12;
                                                                }
                                                            },
                                                        );
                                                    }}
                                                    options={[
                                                        {
                                                            key: 0,
                                                            text: "PCP",
                                                            value: "PCP",
                                                        },
                                                        {
                                                            key: 1,
                                                            text: "Corporate",
                                                            value: "CORPORATE",
                                                        },
                                                    ]}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field='name' />
                                                <label className='with-input'>
                                                    Name <b>*</b>
                                                </label>
                                                <Input
                                                    disabled={contract.used > 0}
                                                    name='name'
                                                    value={contract.name}
                                                    onChange={({ target }) => {
                                                        contract.useStateManager(
                                                            (model) => {
                                                                model.name =
                                                                    target.value;
                                                            },
                                                        );
                                                    }}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field='notes' />
                                                <label className='with-input'>
                                                    Notes
                                                </label>
                                                <Form className='mt-3'>
                                                    <TextArea
                                                        placeholder={
                                                            "Please enter any notes about this contract."
                                                        }
                                                        name='notes'
                                                        value={contract.notes}
                                                        onChange={({
                                                            target,
                                                        }) => {
                                                            contract.useStateManager(
                                                                (model) => {
                                                                    model.notes =
                                                                        target.value;
                                                                },
                                                            );
                                                        }}
                                                    />
                                                </Form>
                                            </Segment>
                                            {contract.allocated !== null && (
                                                <>
                                                    <Segment vertical>
                                                        <ValidationError field='allocated' />
                                                        <label className='with-input'>
                                                            Allocated stock{" "}
                                                            <b>*</b>
                                                        </label>
                                                        <NumberInput
                                                            disabled
                                                            value={
                                                                contract.allocated
                                                            }
                                                            onChange={(
                                                                value,
                                                            ) => {
                                                                contract.useStateManager(
                                                                    (model) => {
                                                                        model.allocated =
                                                                            value;
                                                                    },
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={
                                                                calculateAvailable() ===
                                                                0
                                                            }
                                                            className='ml-3'
                                                            onClick={(
                                                                value,
                                                            ) => {
                                                                contract.useStateManager(
                                                                    (model) => {
                                                                        model.allocated -= 1;
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            <b>-</b>
                                                        </Button>
                                                        <Button
                                                            onClick={(
                                                                value,
                                                            ) => {
                                                                contract.useStateManager(
                                                                    (model) => {
                                                                        model.allocated += 1;
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            <b>+</b>
                                                        </Button>
                                                    </Segment>
                                                    <Segment vertical>
                                                        <ValidationError field='reserved' />
                                                        <label className='with-input'>
                                                            Reserved stock{" "}
                                                            <b>*</b>
                                                        </label>
                                                        <NumberInput
                                                            disabled
                                                            value={
                                                                contract.reserved
                                                            }
                                                            onChange={(
                                                                value,
                                                            ) => {
                                                                contract.useStateManager(
                                                                    (model) => {
                                                                        model.reserved =
                                                                            value;
                                                                    },
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            className='ml-3'
                                                            disabled={
                                                                contract.reserved ===
                                                                0
                                                            }
                                                            onClick={(
                                                                value,
                                                            ) => {
                                                                contract.useStateManager(
                                                                    (model) => {
                                                                        model.reserved -= 1;
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            <b>-</b>
                                                        </Button>
                                                        <Button
                                                            disabled={
                                                                calculateAvailable() ===
                                                                0
                                                            }
                                                            onClick={(
                                                                value,
                                                            ) => {
                                                                contract.useStateManager(
                                                                    (model) => {
                                                                        model.reserved += 1;
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            <b>+</b>
                                                        </Button>
                                                    </Segment>
                                                    <Segment vertical>
                                                        <ValidationError field='reserved' />
                                                        <label className='with-input'>
                                                            Used stock <b>*</b>
                                                        </label>
                                                        <NumberInput
                                                            disabled
                                                            value={
                                                                contract.used
                                                            }
                                                        />
                                                        <Button
                                                            primary
                                                            className='theme ml-3'
                                                            onClick={() =>
                                                                setShowAdjustmentModal(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            Adjust
                                                        </Button>
                                                    </Segment>
                                                    <Segment vertical>
                                                        <ValidationError field='reserved' />
                                                        <label className='with-input'>
                                                            Available stock
                                                        </label>
                                                        <NumberInput
                                                            disabled
                                                            value={calculateAvailable()}
                                                        />
                                                    </Segment>
                                                </>
                                            )}
                                            <Segment vertical>
                                                <ValidationError field='resalePrice' />
                                                <label className='with-input'>
                                                    Resale price <b>*</b>
                                                </label>
                                                <NumberInput
                                                    value={contract.resalePrice}
                                                    onChange={(value) => {
                                                        contract.useStateManager(
                                                            (model) => {
                                                                model.resalePrice =
                                                                    value;
                                                            },
                                                        );
                                                    }}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field='activatesAt' />
                                                <label className='with-input'>
                                                    Activation date <b>*</b>
                                                </label>
                                                <DatePicker
                                                    disabled={contract.used > 0}
                                                    value={contract.activatesAt}
                                                    onDayChange={(value) => {
                                                        contract.useStateManager(
                                                            (model) => {
                                                                model.activatesAt =
                                                                    value;
                                                            },
                                                        );
                                                    }}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field='expiresAt' />
                                                <label className='with-input'>
                                                    Expiration date <b>*</b>
                                                </label>
                                                <DatePicker
                                                    disabled={contract.used > 0}
                                                    value={contract.expiresAt}
                                                    onDayChange={(value) => {
                                                        contract.useStateManager(
                                                            (model) => {
                                                                model.expiresAt =
                                                                    value;
                                                            },
                                                        );
                                                    }}
                                                    dayPickerProps={{
                                                        disabledDays: (day) => {
                                                            return isBefore(
                                                                day,
                                                                contract.activatesAt,
                                                            );
                                                        },
                                                    }}
                                                />
                                            </Segment>
                                            <Segment vertical>
                                                <label className='with-input'>
                                                    Club
                                                </label>
                                                <GolfClubSearchSelect
                                                    disabled={contract.used > 0}
                                                    value={confirmedClubId}
                                                    onChange={
                                                        onChangeConfirmedClub
                                                    }
                                                />
                                                <Link
                                                    to={`/golf-clubs/${confirmedClubId}`}
                                                    key={
                                                        "link-" +
                                                        confirmedClubId
                                                    }
                                                    className='ml-3'
                                                >
                                                    <Button
                                                        primary
                                                        className='theme'
                                                        key={
                                                            "view-" +
                                                            confirmedClubId
                                                        }
                                                    >
                                                        View
                                                    </Button>
                                                </Link>
                                            </Segment>
                                            <Segment vertical>
                                                <ValidationError field='course' />
                                                <label className='with-input'>
                                                    Course
                                                </label>
                                                <Select
                                                    search
                                                    disabled={contract.used > 0}
                                                    value={contract.course._id}
                                                    onChange={(
                                                        _,
                                                        { value },
                                                    ) => {
                                                        contract.useStateManager(
                                                            (model) => {
                                                                const selected =
                                                                    courses.find(
                                                                        ({
                                                                            _id,
                                                                        }) =>
                                                                            _id ===
                                                                            value,
                                                                    );
                                                                model.course =
                                                                    selected;
                                                                fetchSelectedCourseContracts(
                                                                    value,
                                                                );
                                                            },
                                                        );
                                                    }}
                                                    options={courses.map(
                                                        (club, index) => {
                                                            return {
                                                                key: index,
                                                                text: club.name,
                                                                value: club._id,
                                                            };
                                                        },
                                                    )}
                                                />
                                                {courseContracts.length > 0 && (
                                                    <>
                                                        <p className='mt-3'>
                                                            This course has the
                                                            following additional
                                                            contracts:
                                                        </p>
                                                        {courseContracts
                                                            .filter(
                                                                ({ _id }) =>
                                                                    _id !==
                                                                    contract._id,
                                                            )
                                                            .map(
                                                                (
                                                                    item,
                                                                    index,
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className='mt-3'
                                                                    >
                                                                        <Link
                                                                            className='mr-2'
                                                                            to={`/partner-club-contracts/${item._id}`}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                        {
                                                                            item.statusLabel
                                                                        }
                                                                        <span className='ml-3'>
                                                                            Activation:{" "}
                                                                            {format(
                                                                                item.activatesAt,
                                                                                "dd/MM/yyyy",
                                                                            )}
                                                                        </span>
                                                                        <span className='ml-3'>
                                                                            Expiry:{" "}
                                                                            {format(
                                                                                item.expiresAt,
                                                                                "dd/MM/yyyy",
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                ),
                                                            )}
                                                    </>
                                                )}
                                            </Segment>
                                            <Button
                                                size='large'
                                                floated='right'
                                                className='theme mt-3'
                                                primary
                                                onClick={save}
                                                disabled={saving}
                                                loading={saving}
                                            >
                                                Save
                                            </Button>
                                        </Tab>
                                        <Tab
                                            eventKey='invoices'
                                            title='Invoices'
                                        >
                                            <Invoices
                                                contract={contract}
                                                refresh={fetchData}
                                            />
                                        </Tab>
                                        <Tab eventKey='usage' title='Usage'>
                                            <Usage contract={contract} />
                                        </Tab>
                                        <Tab eventKey='manage' title='Manage'>
                                            <p className='mt-3'>Delete</p>
                                            {contract.used > 0 && (
                                                <p>
                                                    <b>
                                                        This contract has been
                                                        used and can therefore
                                                        no longer be deleted
                                                    </b>
                                                </p>
                                            )}
                                            <p>
                                                <small>
                                                    Deleted contracts are
                                                    recoverable at a later date.
                                                </small>
                                            </p>
                                            <Button
                                                primary
                                                onClick={deleteContract}
                                                className='red'
                                                disabled={
                                                    saving || contract.used > 0
                                                }
                                                loading={saving}
                                            >
                                                Delete
                                            </Button>
                                        </Tab>
                                    </Tabs>
                                    <Modal open={showAdjustmentModal}>
                                        <Modal.Header>
                                            Stock adjustment
                                        </Modal.Header>
                                        <Modal.Content>
                                            <div className='row'>
                                                <div className='col-1'>
                                                    <h2>
                                                        {usedAdjustment > 0 && (
                                                            <span>+</span>
                                                        )}
                                                        {usedAdjustment}
                                                    </h2>
                                                </div>
                                                <div className='col-11'>
                                                    <Button
                                                        className='ml-3'
                                                        disabled={
                                                            contract.used ===
                                                            0 - usedAdjustment
                                                        }
                                                        onClick={() => {
                                                            setUsedAdjustment(
                                                                usedAdjustment -
                                                                    1,
                                                            );
                                                        }}
                                                    >
                                                        <b>-</b>
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            calculateAvailable() ===
                                                            0
                                                        }
                                                        onClick={() => {
                                                            setUsedAdjustment(
                                                                usedAdjustment +
                                                                    1,
                                                            );
                                                        }}
                                                    >
                                                        <b>+</b>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <Form className='mt-3'>
                                                        <label className='with-input'>
                                                            Please provide a
                                                            detailed reason for
                                                            this adjustment
                                                            using a minimum of
                                                            20 characters
                                                        </label>
                                                        <TextArea
                                                            placeholder={
                                                                "Please describe the reason for this adjustment"
                                                            }
                                                            value={
                                                                usedAdjustmentReason
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) =>
                                                                setUsedAdjustmentReason(
                                                                    target.value,
                                                                )
                                                            }
                                                        />
                                                    </Form>
                                                </div>
                                            </div>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button
                                                content='Cancel'
                                                onClick={() =>
                                                    setShowAdjustmentModal(
                                                        showAdjustmentModal ===
                                                            false,
                                                    )
                                                }
                                            />
                                            <Button
                                                primary
                                                className='theme'
                                                content='Confirm'
                                                onClick={storeAdjustment}
                                                disabled={
                                                    usedAdjustment === 0 ||
                                                    usedAdjustmentReason.length <
                                                        20
                                                }
                                            />
                                        </Modal.Actions>
                                    </Modal>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
