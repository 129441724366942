import axios from "axios";
import Member from "./Member";
import { Label, Loader } from "semantic-ui-react";
const config = require("../config");

class Communication {
    constructor(properties) {
        Object.assign(
            this,
            {
                sendToEntities: [],
                sendToRoles: [],
                sendToUsers: [],
                communicationType: "THIRD_PARTY_MARKETING",
            },
            properties,
        );
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "communications", {
            params,
        });
        return {
            communications: data.data.map(
                (comunication) => new Communication(comunication),
            ),
            total: data.total,
        };
    }

    static async getById(id) {
        const { data } = await axios.get(
            config.api_url + "communications/" + id,
        );
        return new Communication(data.data);
    }

    async getStatus() {
        const { data } = await axios.get(
            config.api_url + "communications/" + this._id + "/status",
        );
        return data.data;
    }

    async getRecipients(params) {
        const { data } = await axios.get(
            config.api_url + "communications/" + this._id + "/recipients",
            { params },
        );
        return {
            recipients: data.data.map((member) => new Member(member)),
            total: data.total,
        };
    }

    async update() {
        const { data } = await axios.put(
            config.api_url + "communications/" + this._id,
            this,
        );
        return new Communication(data.data);
    }

    async store() {
        const { data } = await axios.post(
            config.api_url + "communications",
            this,
        );
        return new Communication(data.data);
    }

    delete() {
        return axios.delete(config.api_url + "communications/" + this._id);
    }

    send() {
        return axios.post(
            config.api_url + "communications/" + this._id + "/send",
        );
    }

    sendTest(recipient) {
        return axios.post(
            config.api_url + "communications/" + this._id + "/send-test",
            { recipient },
        );
    }

    canEdit() {
        return typeof this.sentAt === "undefined" || this.sentAt === null;
    }

    canSend() {
        return ["DRAFT", "FAILED", "SENT_WITH_FAILURES"].includes(this.status);
    }

    inPollingStatus() {
        return ["AWAITING_SEND", "SENDING"].includes(this.status);
    }

    statusLabel() {
        const loader = (
            <Loader className='ml-3' size='tiny' inline active inverted />
        );
        if (this.status === "DRAFT") {
            return <Label color='blue'>Draft</Label>;
        }

        if (this.status === "AWAITING_SEND") {
            return <Label color='purple'>Awaiting send{loader}</Label>;
        }

        if (this.status === "SENDING") {
            return <Label color='teal'>Sending{loader}</Label>;
        }

        if (this.status === "SENT") {
            return <Label color='green'>Sent</Label>;
        }

        if (this.status === "SENT_WITH_FAILURES") {
            return <Label color='orange'>Sent with failures</Label>;
        }

        if (this.status === "FAILED") {
            return <Label color='red'>Failed</Label>;
        }

        return <Label color='grey'>Unknown</Label>;
    }

    statusDescription() {
        if (this.status === "DRAFT") {
            return "This communication is ready to be sent to the configured recipients.";
        }

        if (this.status === "AWAITING_SEND") {
            return "This communication is currently queued for sending and will update automatically.";
        }

        if (this.status === "SENDING") {
            return "This communication is currently sending and will update automatically.";
        }

        if (this.status === "SENT") {
            return "This communication was successfully sent to all configured recipients.";
        }

        if (this.status === "SENT_WITH_FAILURES") {
            return "This communication was successfully sent to some of the configured recipients. Some recipients failed to send. You can resend this communication to reattempt delivery to any failures.";
        }

        if (this.status === "FAILED") {
            return "This communication failed to send. You can resend this communication to reattempt delivery.";
        }

        return "The status of this communication is not known.";
    }
}

export default Communication;
