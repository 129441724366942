import React, { useState, useEffect } from "react";
import { Input, Checkbox, Select } from "semantic-ui-react";
import { isSameDay, setYear, setMonth, setDate } from "date-fns";
import GolfCourse from "../../../models/GolfCourse";
import DatePicker from "../../../assets/components/DatePicker";

const CourseAvailabilityCalendar = ({
    dateValue = new Date(),
    timeValue = "10:00 - 11:00",
    member,
    course,
    voucher,
    onChangeDate,
    onChangeTime,
    isCreditBooking = false,
    showTimeslotSelection = false,
    showTimeInput = false,
    bookingId = null,
    defaultFirstAvailable = false,
}) => {
    const [month, setSelectedMonth] = useState(dateValue.getMonth());
    const [year, setSelectedYear] = useState(dateValue.getFullYear());
    const [availableDates, setAvailableDates] = useState([]);
    const [selectedDayAvailability, setSelectedDayAvailability] =
        useState(null);
    const [overrideAvailableDates, setOverrideAvailableDates] = useState(false);

    const timeSlots = [
        "06:00 - 07:00",
        "07:00 - 08:00",
        "08:00 - 09:00",
        "09:00 - 10:00",
        "10:00 - 11:00",
        "11:00 - 12:00",
        "12:00 - 13:00",
        "13:00 - 14:00",
        "14:00 - 15:00",
        "15:00 - 16:00",
        "16:00 - 17:00",
        "17:00 - 18:00",
        "18:00 - 19:00",
        "19:00 - 20:00",
        "20:00 - 21:00",
        "21:00 - 22:00",
    ];

    const timeOptions = () => {
        let options = timeSlots;
        if (
            selectedDayAvailability !== null &&
            overrideAvailableDates === false
        ) {
            const { am = null, pm = null } = selectedDayAvailability;
            if (am !== null && pm === null) {
                options = timeSlots.slice(0, 6);
            }
            if (am === null && pm !== null) {
                options = timeSlots.slice(6);
            }
        }

        return options;
    };

    useEffect(() => {
        const getDates = async () => {
            if (overrideAvailableDates === true) return;
            let availability = [];
            if (isCreditBooking === true) {
                availability = await GolfCourse.creditAvailability(
                    course,
                    month + 1,
                    year,
                    member,
                    voucher,
                    bookingId,
                );
            } else {
                availability = await GolfCourse.availability(
                    course,
                    month + 1,
                    year,
                    member,
                    voucher,
                );
            }

            let selectedDate = null;
            for (const available of availability) {
                let availableAsDate = new Date();
                availableAsDate = setYear(availableAsDate, year);
                availableAsDate = setMonth(availableAsDate, month);
                availableAsDate = setDate(availableAsDate, available.day);
                if (isSameDay(availableAsDate, dateValue)) {
                    selectedDate = availableAsDate;
                    setSelectedDayAvailability(
                        overrideAvailableDates ? null : available,
                    );
                    break;
                }
            }

            if (selectedDate === null) {
                selectedDate = new Date();
                if (availability.length === 0) {
                    setSelectedDayAvailability(null);
                } else {
                    if (overrideAvailableDates === true) {
                        setSelectedDayAvailability(null);
                    } else if (defaultFirstAvailable === true) {
                        const first = availability[0];
                        selectedDate = setYear(selectedDate, year);
                        selectedDate = setMonth(selectedDate, month);
                        selectedDate = setDate(selectedDate, first.day);
                        setSelectedDayAvailability(availability[0]);
                        setSelectedMonth(selectedDate.getMonth());
                        setSelectedYear(selectedDate.getFullYear());
                        onChangeDate(selectedDate);
                    }
                }
            }

            setAvailableDates(availability);
        };

        getDates();
    }, [
        member,
        course,
        voucher,
        isCreditBooking,
        month,
        year,
        overrideAvailableDates,
    ]);

    useEffect(() => {
        if (showTimeslotSelection === true) {
            const options = timeOptions();
            if (options.includes(timeValue) === false) {
                onChangeTime(options[0]);
            }
        }
    }, [selectedDayAvailability]);

    const getDatePickerProps = () => {
        const properties = {
            onMonthChange: (month) => {
                setAvailableDates([]);
                setSelectedMonth(month.getMonth());
                setSelectedYear(month.getFullYear());
            },
        };
        if (overrideAvailableDates === false) {
            properties.firstDayOfWeek = 1;
            properties.modifiers = {
                selected: (day) => isSameDay(day, dateValue),
            };
            properties.modifiersStyles = {
                selected: {
                    color: "white",
                    backgroundColor: "#5d637d",
                },
            };
            properties.disabledDays = (dateOption) => {
                return (
                    availableDates.some(({ day }) => {
                        let available = new Date();
                        available = setYear(available, year);
                        available = setMonth(available, month);
                        available = setDate(available, day);

                        return isSameDay(dateOption, available);
                    }) === false
                );
            };
        } else {
            properties.disabledDays = {
                before: new Date(),
            };
        }

        return properties;
    };

    return (
        <>
            <DatePicker
                value={dateValue}
                onDayChange={(value) => {
                    const availabilityForDate =
                        availableDates.find(
                            ({ day }) => day === value.getDate(),
                        ) ?? null;
                    setSelectedDayAvailability(availabilityForDate);
                    onChangeDate(value);
                }}
                dayPickerProps={getDatePickerProps()}
            />
            {showTimeslotSelection && (
                <Select
                    value={timeValue}
                    className='ml-3'
                    onChange={(_, { value }) => onChangeTime(value)}
                    placeholder='Please select a time slot'
                    options={timeOptions().map((time, index) => {
                        return {
                            key: index,
                            text: time,
                            value: time,
                        };
                    })}
                />
            )}
            {showTimeInput && (
                <Input
                    type='time'
                    className='ml-3'
                    value={timeValue}
                    placeholder='Please choose a time'
                    onChange={({ target }) => onChangeTime(target.value)}
                />
            )}
            <Checkbox
                toggle
                label='Override available dates'
                checked={overrideAvailableDates}
                className='ml-3'
                onChange={() => {
                    setOverrideAvailableDates(overrideAvailableDates === false);
                }}
            />
        </>
    );
};

export default CourseAvailabilityCalendar;
