import axios from "axios";
import logoPlaceholder from "../assets/images/camera-placeholder.png";
import GolfCourse from "./GolfCourse";
const config = require("../config");

class GolfClub {
    constructor(properties) {
        this.location = [];
        this.removeImages = [];
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.images = [];
        this.courses = [];
        this.excludedTimes = [];
        this.notes = [];
        this.unavailable = [];
        this.contacts = [];
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "clubs", { params });
        return {
            clubs: data.data.map((club) => new GolfClub(club)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "clubs/list", {
            params,
        });
        return data.data.map((club) => new GolfClub(club));
    }

    static async coordinates(params = {}) {
        const { data } = await axios.get(config.api_url + "clubs/coordinates", {
            params,
        });
        return data.data;
    }

    async reviews(params = {}) {
        const { data } = await axios.get(
            config.api_url + "clubs/" + this._id + "/reviews",
            {
                params,
            },
        );
        return data.data;
    }

    async reviewStats() {
        const { data } = await axios.get(
            config.api_url + "clubs/" + this._id + "/review-stats",
        );
        return data.data;
    }

    static async show(id) {
        const { data } = await axios.get(config.api_url + "clubs/" + id);
        return new GolfClub(data.data);
    }

    async nearby() {
        const { data } = await axios.get(
            config.api_url + "clubs/" + this._id + "/nearby",
        );
        return data.data;
    }

    update() {
        return axios.put(config.api_url + "clubs/" + this._id, {
            name: this.name,
            surname: this.surname,
            address1: this.address1,
            address2: this.address2,
            town: this.town,
            county: this.county,
            postcode: this.postcode,
            country: this.country,
            description: this.description,
            otherInformation: this.otherInformation,
            location: this.location,
            excludedTimes: this.excludedTimes,
            unavailable: this.unavailable,
            level: this.level,
            type: this.type,
            partnerStatus: this.partnerStatus,
            contacts: this.contacts,
            website: this.website,
            rankingLink: this.rankingLink,
            affiliations: this.affiliations,
            facilities: this.facilities,
            isFeatured: this.isFeatured,
            hideGolfBreaksAdvert: this.hideGolfBreaksAdvert,
            golfBreaksRegion: this.golfBreaksRegion,
            vatExempt: this.vatExempt,
            showForRoles: this.showForRoles,
            phoneNumbers: this.phoneNumbers,
            emailAddresses: this.emailAddresses,
            bookingLeadTime: this.bookingLeadTime,
            bookingMaxTime: this.bookingMaxTime,
            bookingTerms: this.bookingTerms,
            currency: this.currency?._id,
            entitiesShow: this.entitiesShow,
            entitiesHide: this.entitiesHide,
        });
    }

    async store() {
        const { data } = await axios.post(config.api_url + "clubs", {
            emailAddresses: this.emailAddresses,
            phoneNumbers: this.phoneNumbers,
            name: this.name,
            address1: this.address1,
            address2: this.address2,
            town: this.town,
            county: this.county,
            postcode: this.postcode,
            country: this.country,
            currency: this.currency?._id,
        });

        return new GolfClub(data.data);
    }

    delete() {
        return axios.delete(config.api_url + "clubs/" + this._id);
    }

    async coursesIndex(params = {}) {
        const { data } = await axios.get(
            config.api_url + "clubs/" + this._id + "/courses",
            { params },
        );
        return data.data.map((club) => new GolfCourse(club));
    }

    async reviewsIndex(params = {}) {
        const { data } = await axios.get(
            config.api_url + "clubs/" + this._id + "/reviews",
            { params },
        );

        return { data: data.data, total: data.total };
    }

    async updateLogo(logo) {
        const response = await axios.put(
            config.api_url + "clubs/" + this._id + "/logo",
            {
                logo,
            },
        );

        return response.data.data;
    }

    async storeImage(image) {
        const response = await axios.post(
            config.api_url + "clubs/" + this._id + "/images",
            {
                image,
            },
        );

        return response.data.data;
    }

    async updateImage(imageId, title) {
        const response = await axios.put(
            config.api_url + "clubs/" + this._id + "/images/" + imageId,
            { title },
        );

        return response.data.data;
    }

    deleteImage(imageId) {
        return axios.delete(
            config.api_url + "clubs/" + this._id + "/images/" + imageId,
        );
    }

    async storeNote(note) {
        const response = await axios.post(
            config.api_url + "clubs/" + this._id + "/notes",
            {
                note,
            },
        );

        return response.data.data;
    }

    get logoUrl() {
        if (typeof this.newLogo !== "undefined") {
            return this.newLogo;
        }

        if (typeof this.logo !== "undefined") {
            return config.content_url + "logos/" + this.logo;
        }

        return logoPlaceholder;
    }

    static clubImage(image) {
        return config.content_url + "clubs/images/" + image.image;
    }

    hasValidCoordinates() {
        return (
            Array.isArray(this.location) &&
            typeof this.location[0] === "number" &&
            typeof this.location[1] === "number"
        );
    }
}

export default GolfClub;
