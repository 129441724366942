import React, { Component } from "react";
import {
    Input,
    Segment,
    Form,
    Checkbox,
    TextArea,
    Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import DatePicker from "../DatePicker";
import { isBefore, startOfDay, endOfDay } from "date-fns";
import EntityAvailability from "./EntityAvailability";
import MembershipTypeSearchSelect from "../../../assets/components/MembershipTypeSearchSelect";

class Availability extends Component {
    constructor(props) {
        super(props);

        this.daysOfWeek = [
            {
                key: 0,
                value: "monday",
                text: "Monday",
            },
            {
                key: 1,
                value: "tuesday",
                text: "Tuesday",
            },
            {
                key: 2,
                value: "wednesday",
                text: "Wednesday",
            },
            {
                key: 3,
                value: "thursday",
                text: "Thursday",
            },
            {
                key: 4,
                value: "friday",
                text: "Friday",
            },
            {
                key: 5,
                value: "saturday",
                text: "Saturday",
            },
            {
                key: 6,
                value: "sunday",
                text: "Sunday",
            },
        ];

        this.seasons = [
            {
                key: 0,
                value: "winter",
                text: "Winter",
            },
            {
                key: 1,
                value: "summer",
                text: "Summer",
            },
        ];

        this.times = [
            {
                key: 0,
                value: "am",
                text: "AM",
            },
            {
                key: 1,
                value: "pm",
                text: "PM",
            },
        ];
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <>
                        <span className='has-error'>
                            {" "}
                            {this.props.validation[field][0]}{" "}
                        </span>
                        <br />
                    </>
                );
            }
        }
    }

    onChangeEntityShowHide(show, hide) {
        const { club } = this.props;
        club.entitiesShow = show;
        club.entitiesHide = hide;
        this.props.update(club);
    }

    onChangeShowForRoles(value) {
        const { club } = this.props;
        club.showForRoles = value;
        this.props.update(club);
    }

    addUnavailablePeriod() {
        const { club } = this.props;
        club.unavailable.push({
            from: startOfDay(new Date()),
            to: endOfDay(new Date()),
        });
        this.props.update(club);
    }

    onChangeUnavailableFrom(index, value) {
        const { club } = this.props;
        const period = club.unavailable[index];
        period.from = startOfDay(value);
        if (isBefore(period.to, period.from)) {
            period.to = endOfDay(period.from);
        }
        this.props.update(club);
    }

    onChangeUnavailableTo(index, value) {
        const { club } = this.props;
        value.setHours(0);
        club.unavailable[index].to = endOfDay(value);
        this.props.update(club);
    }

    onChangeUnavailableToNoExpiry(index) {
        const { club } = this.props;
        if (club.unavailable[index].to === null) {
            club.unavailable[index].to = Date.now();
        } else {
            club.unavailable[index].to = null;
        }

        this.props.update(club);
    }

    onChangeUnavailableDescription(index, value) {
        const { club } = this.props;
        club.unavailable[index].description = value;
        this.props.update(club);
    }

    removeUnavailablePeriod(index) {
        const { club } = this.props;
        club.unavailable.splice(index, 1);
        this.props.update(club);
    }

    gatherInputInt(property, value) {
        const { club } = this.props;
        club[property] = parseInt(value, 10) || 0;
        this.props.update(club);
    }

    onChangeBookingTerms(value) {
        const { club } = this.props;
        club.bookingTerms = value;
        this.props.update(club);
    }

    noExpiryOverlay = ({ classNames, selectedDay, children, ...props }) => {
        const index = props.input.props.name;
        const period = this.props.club.unavailable[index];
        return (
            <div className='pb-3' {...props}>
                <div className={classNames.overlay}>
                    {children}
                    <div className='text-center'>
                        <Checkbox
                            toggle
                            name='primary'
                            label='No expiry'
                            value={index}
                            checked={period.to === null}
                            onChange={() =>
                                this.onChangeUnavailableToNoExpiry(index)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    unavailablePeriods() {
        return this.props.club.unavailable.map((period, index) => {
            return (
                <Segment vertical key={index}>
                    <div className='row'>
                        <div className='col-12 col-lg-3'>
                            <label className='with-input'>
                                Unavailable from
                            </label>
                            <DatePicker
                                value={new Date(period.from)}
                                onDayChange={(value) => {
                                    this.onChangeUnavailableFrom(index, value);
                                }}
                                dayPickerProps={{
                                    disabledDays: (day) =>
                                        isBefore(day, new Date()),
                                }}
                            />
                        </div>
                        <div className='col-12 col-lg-3'>
                            <label className='with-input'>Unavailable to</label>
                            <DatePicker
                                value={new Date(period.to)}
                                onDayChange={(value) => {
                                    this.onChangeUnavailableTo(index, value);
                                }}
                                dayPickerProps={{
                                    disabledDays: (day) =>
                                        isBefore(day, period.to),
                                }}
                            />
                        </div>

                        <div className='col-9 col-lg-3'>
                            <label className='with-input'>Description</label>
                            <Input
                                fluid
                                name={index}
                                placeholder='Why is this club unavailable?'
                                value={period.description || ""}
                                onChange={({ target }) =>
                                    this.onChangeUnavailableDescription(
                                        index,
                                        target.value,
                                    )
                                }
                            />
                        </div>
                        <div className='col-3 col-lg-3 d-flex align-items-end justify-content-end'>
                            <Button
                                primary
                                className='red mt-3'
                                value={index}
                                onClick={() =>
                                    this.removeUnavailablePeriod(index)
                                }
                            >
                                Remove
                            </Button>
                        </div>
                    </div>
                </Segment>
            );
        });
    }

    render() {
        return (
            <>
                <Segment vertical>
                    <h4>Club unavailable</h4>
                    <p>
                        Define a start and end date for when this club wil be
                        unavailable
                    </p>
                    {this.unavailablePeriods()}
                    <Segment vertical>
                        <Button
                            primary
                            className='theme'
                            onClick={this.addUnavailablePeriod.bind(this)}
                        >
                            Add unavailable period
                        </Button>
                    </Segment>
                </Segment>
                <Segment vertical>
                    <h4>Club visibility</h4>
                    <p>
                        Define which membership types are able to see this club
                        within the MASHIE app. Leave blank to hide this club
                        entirely.
                    </p>
                    <MembershipTypeSearchSelect
                        fluid
                        value={this.props.club.showForRoles}
                        placeholder='Select which memberships can view this club'
                        onChange={(value) => this.onChangeShowForRoles(value)}
                    />
                </Segment>
                <Segment vertical>
                    <EntityAvailability
                        club={this.props.club}
                        onChange={this.onChangeEntityShowHide.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <h4>Booking lead time</h4>
                    <p>
                        How many days should be blocked out before a customer
                        can book?
                    </p>
                    {this.errorsFor("bookingLeadTime")}
                    <Input
                        className='mt-3'
                        fluid
                        name='bookingLeadTime'
                        value={this.props.club.bookingLeadTime ?? ""}
                        onChange={({ target }) =>
                            this.gatherInputInt("bookingLeadTime", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <h4>Booking max time</h4>
                    <p>
                        How many days in the future should a customer be able to
                        book?
                    </p>
                    {this.errorsFor("bookingMaxTime")}
                    <Input
                        className='mt-3'
                        fluid
                        name='bookingMaxTime'
                        value={this.props.club.bookingMaxTime ?? ""}
                        onChange={({ target }) =>
                            this.gatherInputInt("bookingMaxTime", target.value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <h4>Booking Terms</h4>
                    <p>
                        If there are any terms that players need to be aware of
                        when making a booking, please add them here.
                    </p>
                    {this.errorsFor("bookingTerms")}
                    <Form>
                        <TextArea
                            className='mt-3'
                            name='bookingTerms'
                            placeholder='Booking terms'
                            value={this.props.club.bookingTerms ?? ""}
                            onChange={(_, { value }) =>
                                this.onChangeBookingTerms(value)
                            }
                        />
                    </Form>
                </Segment>
                <div className='row'>
                    <div className='col'>
                        <Segment vertical>
                            <Button
                                size='large'
                                floated='right'
                                className='theme'
                                primary
                                onClick={this.props.save}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Availability);
