import React, { Component } from "react";
import { Input, Segment, Select, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import CountrySelect from "../../../assets/components/CountrySelect";

class Addresses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: null,
        };

        this.addressTypes = [
            {
                key: 0,
                value: "Home",
                text: "Home",
            },
            {
                key: 1,
                value: "Work",
                text: "Work",
            },
            {
                key: 2,
                value: "Billing",
                text: "Billing",
            },
        ];
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className='has-error'>
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    add() {
        const { user } = this.props;
        user.addresses.push({
            primary: user.addresses.length === 0,
            addressType: "Work",
            line1: "",
            line2: "",
            town: "",
            county: "",
            postcode: "",
            country: "",
        });
        this.props.update(user);
        this.setState({ editing: user.addresses.length - 1 });
    }

    remove(index) {
        const { user } = this.props;
        user.addresses.splice(index, 1);
        this.props.update(user);
    }

    onChangeField(value, field) {
        const { user } = this.props;
        user.addresses[this.state.editing][field] = value;
        this.props.update(user);
    }

    onChangePrimary(value, index) {
        const { user } = this.props;
        for (const address of user.addresses) {
            address.primary = false;
        }
        user.addresses[index].primary = value.checked;
        this.props.update(user);
    }

    onChangeType(result) {
        const { user } = this.props;
        user.addresses[this.state.editing].addressType = result.value;
        this.props.update(user);
    }

    editor() {
        if (this.state.editing === null) {
            return null;
        }

        const address = this.props.user.addresses[this.state.editing];
        return (
            <>
                <Segment vertical>
                    <label className='with-input'>Type</label>
                    <Select
                        fluid
                        value={address.addressType}
                        options={this.addressTypes}
                        onChange={(_, result) => this.onChangeType(result)}
                        placeholder='Address type'
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Line 1</label>
                    <Input
                        fluid
                        name='line1'
                        value={address.line1}
                        onChange={(event) =>
                            this.onChangeField(event.target.value, "line1")
                        }
                        placeholder='Line 1'
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Line 2</label>
                    <Input
                        fluid
                        name='line2'
                        value={address.line2}
                        onChange={(event) =>
                            this.onChangeField(event.target.value, "line2")
                        }
                        placeholder='Line 2'
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Town</label>
                    <Input
                        fluid
                        name='town'
                        value={address.town}
                        onChange={(event) =>
                            this.onChangeField(event.target.value, "town")
                        }
                        placeholder='Town'
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>County</label>
                    <Input
                        fluid
                        name='county'
                        value={address.county}
                        onChange={(event) =>
                            this.onChangeField(event.target.value, "county")
                        }
                        placeholder='County'
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Postcode</label>
                    <Input
                        fluid
                        name='postcode'
                        value={address.postcode}
                        onChange={(event) =>
                            this.onChangeField(event.target.value, "postcode")
                        }
                        placeholder='Postcode'
                    />
                </Segment>
                <Segment vertical>
                    <label className='with-input'>Country</label>
                    <CountrySelect
                        fluid
                        value={address.country}
                        onChange={(value) => {
                            this.onChangeField(value, "country");
                        }}
                    />
                </Segment>
                <Segment vertical>
                    <Button
                        primary
                        className='theme'
                        onClick={() => this.setState({ editing: null })}
                    >
                        Confirm
                    </Button>
                </Segment>
            </>
        );
    }

    list() {
        if (this.state.editing !== null) {
            return null;
        }

        return this.props.user.addresses.map((address, i) => {
            return (
                <Segment vertical key={i}>
                    <div className='row'>
                        <div className='col-10'>
                            <Checkbox
                                toggle
                                label='Primary'
                                value={i}
                                checked={address.primary}
                                onChange={(_, value) =>
                                    this.onChangePrimary(value, i)
                                }
                            />
                            <p className='m-0'>
                                <b> {address.addressType}</b>
                            </p>
                            <p className='m-0'>
                                <b>Line 1:</b> {address.line1}
                            </p>
                            <p className='m-0'>
                                <b>Line 2:</b> {address.line2}
                            </p>
                            <p className='m-0'>
                                <b>town:</b> {address.town}
                            </p>
                            <p className='m-0'>
                                <b>county:</b> {address.county}
                            </p>
                            <p className='m-0'>
                                <b>postcode:</b> {address.postcode}
                            </p>
                            <p className='m-0'>
                                <b>country:</b> {address.country}
                            </p>
                        </div>
                        <div className='col-2 d-flex align-items-center justify-content-end'>
                            <Button
                                primary
                                className='theme'
                                onClick={() => this.setState({ editing: i })}
                            >
                                Edit
                            </Button>
                            <Button
                                primary
                                className='red'
                                onClick={() => this.remove(i)}
                                value={i}
                            >
                                Remove
                            </Button>
                        </div>
                    </div>
                </Segment>
            );
        });
    }

    render() {
        return (
            <>
                <Segment vertical>
                    {this.errorsFor("addresses")}
                    <label className='with-input'>Addresses</label>
                    {this.list()}
                    {this.editor()}
                    <div className='row'>
                        <div className='col-12'>
                            <Button
                                primary
                                className='theme mt-3'
                                onClick={this.add.bind(this)}
                            >
                                Add address
                            </Button>
                        </div>
                    </div>
                </Segment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Addresses);
