import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";

import NotificationActions from "../../store/actions/notification";
import { Button } from "semantic-ui-react";
import Communication from "../../models/Communication";

class CommunicationsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            communications: [],
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.recipientTypes = {
            sendToAll: "All",
            sendToEntities: "Entites",
            sendToRoles: "Membership types",
            sendToUsers: "Users",
        };
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    deleteCommunication(id) {
        const confirm = window.confirm(
            "Are you sure you want to remove this communication?",
        );
        if (confirm === true) {
            const communication = this.state.communications.find(
                (communication) => communication._id === id,
            );
            communication
                .delete()
                .then((response) => {
                    this.notification("success", "Communication deleted");
                    this.getCommunications(this.state.table);
                })
                .catch((error) => {
                    this.notification(
                        "error",
                        "Failed to delete communication",
                    );
                });
        }
    }

    showDeleteButton(communication) {
        if (typeof communication.sentAt === "undefined") {
            return (
                <Button
                    primary
                    onClick={() => this.deleteCommunication(communication._id)}
                    className='red'
                >
                    Delete
                </Button>
            );
        }
    }

    table() {
        const headers = [
            { key: "createdDate", text: "Created" },
            { key: "status", text: "Status" },
            { key: "sentDate", text: "Sent" },
            { key: "subject", text: "Subject" },
            { key: "body", text: "Body preview" },
            { key: "recipientType", text: "Recipient type" },
            { key: "recipients", text: "No. of recipients" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.communications.map((communication) => {
            return {
                createdDate: {
                    value: new Date(
                        communication.createdAt,
                    ).toLocaleDateString(),
                },
                status: {
                    value: communication.statusLabel(),
                },
                sentDate: {
                    value:
                        typeof communication.sentAt !== "undefined"
                            ? new Date(
                                  communication.sentAt,
                              ).toLocaleDateString()
                            : "Not Sent",
                },
                subject: { value: communication.subject },
                body: { value: communication.body.substring(0, 9) + "..." },
                recipientType: {
                    value: this.recipientTypes[communication.recipientType],
                    search: this.recipientTypes[communication.recipientType],
                },
                recipients: { value: communication.recipientsTotal },
                actions: {
                    value: (
                        <>
                            <Link to={`/communications/${communication._id}`}>
                                <Button primary className='theme'>
                                    View
                                </Button>
                            </Link>
                            {this.showDeleteButton(communication)}
                        </>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getCommunications.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Send date", key: "sentAt" },
                    { text: "Subject", key: "subject" },
                    { text: "Recipient type", key: "recipientType" },
                ]}
                pagination
            />
        );
    }

    getCommunications(params) {
        this.setState({ loading: true });
        Communication.index(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    communications: data.communications,
                    totalRecords: data.total,
                    table: params,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load communications, please try again",
                );
            });
    }

    componentDidMount() {
        this.getCommunications(this.state.table);
    }

    body() {
        return (
            <div className='container-fluid' style={{ overflowX: "scroll" }}>
                <div className='row page-header mt-3'>
                    <div className='col-sm-12 col-md-10'>
                        <h2>Communications</h2>
                    </div>
                    <div className='col-sm-12 col-md-2 text-right'>
                        <Link to='/communications/create'>
                            <Button primary className='theme'>
                                Create
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className='d-flex main'>
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(CommunicationsIndex);
