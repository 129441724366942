import React from "react";
import { Select } from "semantic-ui-react";
import countryList from "../../utilities/countryList";

const CountrySelect = ({
    onChange,
    value,
    fluid = false,
    multiple = false,
}) => {
    return (
        <Select
            fluid={fluid}
            multiple={multiple}
            search
            options={countryList.map((country, index) => {
                return { key: index, value: country, text: country };
            })}
            value={value}
            onChange={(_, result) => onChange(result.value)}
        />
    );
};

export default CountrySelect;
